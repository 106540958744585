const properties = {
    title: 'AccuCyb',
    redirectSignIn: 'https://accucyb.hannover-re.com/',
    redirectSignOut: 'https://accucyb.hannover-re.com/',
    cognitoDomain: 'accucyb-prod.auth.eu-central-1.amazoncognito.com',
    userPoolId: 'eu-central-1_IaY5yoFeo',
    appClientId: '4u2jqjlrrt11998v9eidt4aphr',
    identityPoolId: 'eu-central-1:aab729d1-9606-4990-a868-4c00ef42a986',
    endpointName: "accucyb_ui",
    user_group: "eu-central-1_IaY5yoFeo_oktaprod",
    fe_content: "https://lambda.eu-central-1.amazonaws.com/2015-03-31/functions/accu-cyb-prod-fe_content/invocations",
    fe_content_api: "https://accucyb-api.hannover-re.com",
    bucket: "accucyb-clamav-scanned-prod",
    power_bi_url: "https://app.powerbi.com/groups/b0e8c93e-e8de-4995-9441-20b4c9f6d5ba/list/dashboards",
    power_bi_embedded: {
        authenticationMode: "ServicePrincipal",
        authorityUri: "https://login.microsoftonline.com/common/v2.0",
        scope: "https://analysis.windows.net/powerbi/api",
        apiUrl: "https://api.powerbi.com/",
        clientId: "2dcf5f50-712b-47e3-b913-cbfea9380992",
        workspaceId: "9122bc44-20ed-47a3-9878-842d2262d70d",
        reportId: "1f11d9d0-fc1b-43ed-a748-c9675e7cefe6",
        pbiUsername: "",
        pbiPassword: "",
        clientSecret: "5S8Lo4zSMubI5R._5h-SKW6J2~_A_vRIOy",
        tenantId: "815f40e5-50f9-45a8-ae03-40ad9b986f88"
    }
};

export { properties };